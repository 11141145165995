import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

var h = ""

if(localStorage.logged == null){

  if(localStorage.logged == 1){
    h = "home"
  }else{
    h = "loginf"
  }
}else{
  h = "home"
}

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: h,
    pathMatch: 'full'
  },
  {
    path: 'parkingdet',
    loadChildren: () => import('./parkingdet/parkingdet.module').then( m => m.ParkingdetPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'loginf',
    loadChildren: () => import('./loginf/loginf.module').then( m => m.LoginfPageModule)
  },
  {
    path: 'contactar',
    loadChildren: () => import('./contactar/contactar.module').then( m => m.ContactarPageModule)
  },
  {
    path: 'searchit',
    loadChildren: () => import('./searchit/searchit.module').then( m => m.SearchitPageModule)
  },
  {
    path: 'addaday',
    loadChildren: () => import('./addaday/addaday.module').then( m => m.AddadayPageModule)
  },
  {
    path: 'addadate',
    loadChildren: () => import('./addadate/addadate.module').then( m => m.AddadatePageModule)
  },

  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./perfil/perfil.module').then( m => m.PerfilPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
