import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
//localStorage.apiurl = "http://localhost/ecoparqueos/dash/api/ws.php"
localStorage.apiurl = "https://ecoparqueo.myferby.com/api/ws.php"

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
